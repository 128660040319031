import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import './App.css'
import { PersistGate } from 'redux-persist/integration/react' // Import PersistGate

// fonts
import './assets/fonts/Poppins/Poppins-Black.ttf'
import './assets/fonts/Poppins/Poppins-BlackItalic.ttf'
import './assets/fonts/Poppins/Poppins-Bold.ttf'
import './assets/fonts/Poppins/Poppins-BoldItalic.ttf'

// import '@fontsource/nunito' // Defaults to weight 400
// import '@fontsource/nunito/400.css' // Specify weight
// import '@fontsource/nunito/400-italic.css' // Specify weight and style

// import '@fontsource/raleway' // Import Raleway font

// import '@fontsource/lato' // Defaults to weight 400
// import '@fontsource/lato/400.css' // Specify weight
// import '@fontsource/lato/400-italic.css' // Specify weight and style

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
