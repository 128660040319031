import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
import React from 'react'
import { IoIosArrowDown } from 'react-icons/io'

const DropDown = ({ title, items, onClick, name }) => {
  return (
    <CDropdown>
      <CDropdownToggle className="font-weight-bold" color="white">
        {title} <IoIosArrowDown />
      </CDropdownToggle>
      <CDropdownMenu>
        {items.map((data) => {
          return (
            <CDropdownItem
              onClick={() => onClick({ target: { name: name, value: data.value } })}
              key={data.id}
            >
              {data.label}
            </CDropdownItem>
          )
        })}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default DropDown
