// import Redux
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { thunk } from 'redux-thunk'
import commonReducer from './reducers'
import authReducer from './authReducers'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
const persistConfig = {
  key: 'root',
  storage,
}
const authPersistReducer = persistReducer(persistConfig, authReducer)
export const store = createStore(
  combineReducers({ common: commonReducer, auth: authPersistReducer }),
  applyMiddleware(thunk, logger),
)
export const persistor = persistStore(store)
