import { CButton, CSpinner } from '@coreui/react'
import React from 'react'

const ButtonWithLoader = ({ color, loading, children, onClick }) => {
  return (
    <div>
      <CButton onClick={onClick} className="d-flex " disabled={loading} color={color}>
        {loading && (
          <div>
            <CSpinner style={{ width: '20px', height: '20px' }} />
          </div>
        )}
        <div className="text-white w-100">{children}</div>
      </CButton>
    </div>
  )
}

export default ButtonWithLoader
