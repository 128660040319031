import React, { useState } from 'react'

const HeroCardComponents = ({ cardsData, setIndex, index = 0 }) => {
  const [activeCardIndex, setActiveCardIndex] = useState(0)

  const handleNextClick = (e) => {
    e.preventDefault()
    setActiveCardIndex((prevIndex) => (prevIndex + 1) % numberOfCards)
  }

  const numberOfCards = cardsData.length
  return (
    <>
      <div className="container mt-5 mb-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4  d-md-flex">
          {cardsData.map((card, i) => (
            <div
              onClick={() => {
                setIndex(i)
                console.log('card', card, 'index', i)
              }}
              className="col "
              key={i}
            >
              <div
                className={` ${i === index ? 'shadow-lg' : 'shadow-sm'}`}
                style={{ minHeight: '200px', maxHeight: '200px' }}
              >
                <div className="d-flex justify-content-between position-relative">
                  <img
                    src={process.env.PUBLIC_URL + card.icon}
                    className="card-img-top ml-4 mt-4"
                    style={{ width: '70px' }}
                    alt="Icon"
                  />

                  <div className="position-relative">
                    <div className="position-absolute top-0 start-0 ml-5 mt-4">
                      <img
                        src={process.env.PUBLIC_URL + card.number}
                        className="card-img-top"
                        style={{ width: '40px', height: '30px' }}
                        alt="Number"
                      />
                    </div>
                    <div>
                      <img
                        src={process.env.PUBLIC_URL + '/Media/Frame.png'}
                        className="background-img"
                        style={{ width: '100px', height: '100px', zIndex: -1 }}
                        alt="Background"
                      />
                    </div>
                  </div>
                </div>

                <div className="card-body pl-4 pb-4">
                  <h5
                    className="card-title"
                    style={{
                      fontFamily: 'Poppins-Bold',
                      fontSize: 22,
                      color: '#0C2543',
                    }}
                  >
                    {card.title}
                  </h5>
                  <p className="card-text" style={{ fontSize: 14, fontFamily: 'poppins-regular' }}>
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Carousel Cards */}
    </>
  )
}

export default HeroCardComponents
