import React from 'react'
import Slider from 'react-slick'

const logos = [
  process.env.PUBLIC_URL + '/Media/Logo 1.png',
  process.env.PUBLIC_URL + '/Media/Logo 2.png',
  process.env.PUBLIC_URL + '/Media/Logo 3.png',
  process.env.PUBLIC_URL + '/Media/Logo 4.png',
  process.env.PUBLIC_URL + '/Media/Logo 5.png',
  process.env.PUBLIC_URL + '/Media/Logo 6.png',
  process.env.PUBLIC_URL + '/Media/Logo 7.png',
]

const LogoSlider = () => {
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 992, // Medium devices (tablets, 768px and up)
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576, // Small devices (landscape phones, 576px and up)
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <div className="container mt-5 mb-5">
      <div className="d-flex justify-content-center align-items-center">
        <div className="border-bottom border-dark mb-3" style={{ width: '70px' }}></div>
        <h2
          className="text-center mx-3 mx-md-5 mb-4"
          style={{
            fontFamily: 'Poppins-medium',
            fontSize: '36',
            fontWeight: '500',
            color: '#0C2543',
          }}
        >
          Our Partners
        </h2>
        <div className="border-bottom border-dark mb-3" style={{ width: '70px' }}></div>
      </div>
      <Slider {...settings} className="customer-logos">
        {logos.map((logo, index) => (
          <div key={index} className="slide">
            <img
              src={logo}
              alt={`Logo ${index + 1}`}
              className={`img-fluid ${settings.responsive[1].settings ? 'mb-3' : ''}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default LogoSlider
