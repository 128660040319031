import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonWithLoader from 'src/components/ButtonWithLoader'
import Footer from 'src/components/Clientside/Footer'
import Header from 'src/components/Clientside/Header'
import Hero from 'src/components/Clientside/Hero'
import HeroCard from 'src/components/Clientside/HeroCard'
import MadadghaarAgent from 'src/components/Clientside/MadadghaarAgent'
import Modal from 'src/components/Clientside/Modal'
import OurProject from 'src/components/Clientside/OurProjects'
import PropertiesCard from 'src/components/Clientside/PropertiesCard'
import PropertiesServicesCard from 'src/components/Clientside/PropertiesServicesCard'

import { searhPropertyForPublic } from 'src/store/action'
import DropDown from 'src/components/Clientside/DropDown'
import { Carousel } from 'react-bootstrap'
import InstallmentCard from 'src/components/Clientside/InstallmentCard'
const MIN_VALUES = [
  {
    id: 'MIN_VALUES_1',
    label: '0',
    value: 0,
  },
  {
    id: 'MIN_VALUES_2',
    label: '5,800',
    value: 5800,
  },
  {
    id: 'MIN_VALUES_3',
    label: '11,700',
    value: 11700,
  },
  {
    id: 'MIN_VALUES_4',
    label: '23,400',
    value: 23400,
  },
  {
    id: 'MIN_VALUES_5',
    label: '873,300',
    value: 873300,
  },
]
const MAX_VALUES = [
  {
    id: 'MAX_VALUES_1',
    label: '0',
    value: 0,
  },
  {
    id: 'MAX_VALUES_2',
    label: '5,800',
    value: 5800,
  },
  {
    id: 'MAX_VALUES_3',
    label: '11,700',
    value: 11700,
  },
  {
    id: 'MAX_VALUES_4',
    label: '23,400',
    value: 23400,
  },
  {
    id: 'MAX_VALUES_5',
    label: '873,300',
    value: 873300,
  },
]
const AREA_RANGE_MIN_VALUES = [
  {
    id: 'AREA_RANGE_MIN_VALUES_1',
    label: '0',
    value: 0,
  },
  {
    id: 'AREA_RANGE_MIN_VALUES_2',
    label: '2',
    value: 2,
  },
  {
    id: 'AREA_RANGE_MIN_VALUES_3',
    label: '3',
    value: 3,
  },
  {
    id: 'AREA_RANGE_MIN_VALUES_4',
    label: '5',
    value: 5,
  },
  {
    id: 'AREA_RANGE_MIN_VALUES_5',
    label: '8',
    value: 8,
  },
]
const AREA_RANGE_MAX_VALUES = [
  {
    id: 'AREA_RANGE_MAX_VALUES_1',
    label: '0',
    value: 0,
  },
  {
    id: 'AREA_RANGE_MAX_VALUES_2',
    label: '2',
    value: 2,
  },
  {
    id: 'AREA_RANGE_MAX_VALUES_3',
    label: '3',
    value: 3,
  },
  {
    id: 'AREA_RANGE_MAX_VALUES_4',
    label: '5',
    value: 5,
  },
  {
    id: 'AREA_RANGE_MAX_VALUES_5',
    label: '8',
    value: 8,
  },
]
const Installments = () => {
  const [filter, setFilter] = useState({
    city: 'All',
    location: '',
    propertyType: 'All',
    budgetMin: '0',
    budgetMax: '0',
    areaMin: '0',
    areaMax: '0',
  })
  const { filterPublicPropertyLoading } = useSelector((state) => state.common)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(searhPropertyForPublic(filter))
  }, [])
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value)

    setFilter((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }
  const handleSearch = (e) => {
    e.preventDefault()
    console.log(filter)

    try {
      dispatch(searhPropertyForPublic(filter))
    } catch (error) {}
  }
  return (
    <>
      <Header />
      <Hero
        title={[<div key="line1">Get What You Need Today, Pay in Easy Installments.</div>]}
        imageUrl={process.env.PUBLIC_URL + '/Media/installment.jpeg'}
        customStyle={{ maxWidth: '100%', objectFit: 'cover' }}
        paragraph="We are offering a wide range of installment Plans across Pakistan. We partner with leading companies and dealers to provide tailored payment solutions that fit your needs. With manageable installments and often low or zero interest rates, this service makes large purchases more affordable and accessible, ensuring you can get what you need without financial strain."
      />
      <div className="border-bottom p-2 border-top container">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2">
          <DropDown
            title={'Mobile Phones'}
            items={[{ id: 'mobilePhones  ', label: 'hi', value: 'hi' }]}
          />
          <DropDown title={'Bikes'} items={[{ id: 'mobilePhones  ', label: 'hi', value: 'hi' }]} />
          <DropDown
            title={'Air Conditioners'}
            items={[{ id: 'mobilePhones  ', label: 'hi', value: 'hi' }]}
          />
          <DropDown
            title={'Televisions'}
            items={[{ id: 'mobilePhones  ', label: 'hi', value: 'hi' }]}
          />
          <DropDown
            title={'Electronics'}
            items={[{ id: 'mobilePhones  ', label: 'hi', value: 'hi' }]}
          />
          <DropDown
            title={'Home Appliances'}
            items={[{ id: 'mobilePhones  ', label: 'hi', value: 'hi' }]}
          />
        </div>
      </div>
      {/* filters */}
      <div className="my-5">
        <div className="w-100 my-2">
          <div
            className="d-flex p-2 justify-content-center align-items-center flex-column flex-md-row gap-5 w-75 mx-auto"
            // style={{ background: '#f98387' }}
          >
            <div className="w-75 d-flex flex-column flex-md-row justify-content-center align-items-center gap-2">
              <CFormInput
                type="text"
                id="exampleFormControlInput1"
                placeholder="search"
                name="location"
                aria-describedby="exampleFormControlInputHelpInline"
                onChange={(e) => handleChange(e)}
              />

              <ButtonWithLoader loading={filterPublicPropertyLoading} onClick={handleSearch}>
                Search
              </ButtonWithLoader>
            </div>
          </div>
        </div>
      </div>
      {/* <HeroCard /> */}
      <InstallmentCard />
      <div className="container">
        <h2 className="text-center my-3">Special Offers for limited time</h2>
        <Carousel className={'w-100'}>
          <Carousel.Item>
            <img
              style={{ objectFit: 'contain', maxHeight: '500px' }}
              className="d-block w-100"
              src={
                'https://www.hcsupermart.com/wp-content/uploads/2024/01/3-2-HOME-APPLIANCES-2-1.jpg'
              }
            />
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <PropertiesCard /> */}
      <div style={{ height: '60vh' }} className="w-75 container my-5">
        <iframe
          className="object-fit-contain w-100 h-100"
          src="https://www.youtube.com/embed/X7rTL_Y54oI?si=FCuyEAuHBOXhizhu"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <OurProject />

      <MadadghaarAgent title={['Why Madadghaar?']} />
      <Footer />
    </>
  )
}

export default Installments
