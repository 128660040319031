import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HeroCardComponents from './HeroCardComponents'
import { cardsData } from 'src/utils/constants'

const Hero = ({
  title,
  imageUrl,
  paragraph,
  customClass,
  customStyle,
  customClassText,
  hideCards,
  heroButton,
  buttonOne,
  buttonTwoCondition,
}) => {
  const [index, setIndex] = useState(0)

  return (
    <>
      <div className="container mt-5  pt-3 pb-3">
        <div className="row">
          <div className="col-md-6">
            <div className={customClassText}>
              <div>
                <div>
                  <div>
                    <div>
                      <p
                        className="bg-white rounded-pill shadow pt-2 pb-2 pr-3 pl-3 d-inline-block small"
                        style={{ color: '#B7242A', fontFamily: 'Poppins-SemiBold', fontSize: 12 }}
                      >
                        Now you&apos;ll have no more worries!
                      </p>
                      <h1
                        style={{
                          fontFamily: 'Poppins-Bold',
                        }}
                      >
                        {title}
                      </h1>
                      <p
                        style={{
                          fontFamily: 'Poppins-Medium',
                          fontSize: 14,
                          color: '#565A5C',
                        }}
                        className="small"
                      >
                        {paragraph}
                      </p>

                      {buttonOne && (
                        <div className="d-flex align-items-center">
                          <div className="  text-center pt-2 pb-2">
                            {heroButton && <h5 className="card-title">{heroButton}</h5>}
                            <div className="btn-group d-flex flex-wrap mt-3" role="group">
                              <button
                                type="button"
                                className="btn btn-primary "
                                style={{ color: 'white' }}
                              >
                                {buttonOne}
                              </button>
                              {buttonTwoCondition && (
                                <button type="button" className="btn btn-secondary">
                                  Partner
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={customClass}>
              <img
                src={imageUrl}
                className="img-fluid w-75 float-right"
                style={customStyle}
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>

      {/* Hero Cards */}
    </>
  )
}

Hero.propTypes = {
  title: PropTypes.arrayOf(PropTypes.node).isRequired,
  customClass: PropTypes.string.isRequired,
  customStyle: PropTypes.object.isRequired,
  customClassText: PropTypes.object.isRequired,
  imageUrl: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  heroButton: PropTypes.string.isRequired,
  buttonOne: PropTypes.string.isRequired,
  buttonTwoCondition: PropTypes.string.isRequired,
  // hideCards: PropTypes.bool,
}

export default Hero
