import AWS from 'aws-sdk'
export const s3 = new AWS.S3({
  accessKeyId: 'AKIA6GBMFTVTHKI3E3ML',
  secretAccessKey: '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',
  region: 'eu-north-1',
})
export const uploadImagesToS3 = (files) => {
  console.log('files aii hain', files)

  const uploadPromises = []

  files.forEach((file) => {
    const uploadParams = {
      Bucket: 'madadghar',
      Key: file.name,
      Body: file,
      ACL: 'public-read',
    }

    uploadPromises.push(
      new Promise((resolve, reject) => {
        s3.upload(uploadParams, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      }),
    )
  })

  return Promise.all(uploadPromises)
}
