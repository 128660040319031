import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'
const Header = () => {
  let [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className="">
      <header
        style={{ backgroundColor: '#1D1D1D', color: '#FFFFFF', fontFamily: 'poppins-Regular' }}
      >
        <div className="container  d-flex flex-md-row flex-column justify-content-md-between text-poppins">
          <div className="mt-3  text-md-left text-center">
            <p style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
              <span>
                <i className="fa-solid fa-phone"></i>
              </span>{' '}
              madadgaaragent@gmail.com
            </p>
          </div>
          <div className="mt-3  text-md-left text-center">
            <p>
              {' '}
              <span>
                <i className="fa-solid fa-phone"></i>
              </span>{' '}
              +92 340 400 5000
            </p>
          </div>
          <div className="mt-3 text-md-left text-center">
            <p>
              {' '}
              <span>
                <i className="fa-solid fa-phone"></i>
              </span>{' '}
              +92 340 400 5000
            </p>
          </div>
          <div className="mt-3 text-md-left text-center">
            <p>
              {' '}
              <span>
                <i className="fa-brands fa-whatsapp" style={{ color: '#25D366' }}></i>
              </span>{' '}
              +92 340 400 5000
            </p>
          </div>
        </div>
      </header>

      <nav
        className="container  flex-wrap navbar navbar-expand-lg navbar-light bg-white pt-4 pb-4 pl-2 pr-2"
        style={{ fontFamily: 'poppins-regular' }}
      >
        <button
          className="navbar-toggler"
          data-bs-dismiss="offcanvas"
          data-toggle="collapse"
          data-target="#offcanvasstart"
          onClick={() => setOpen(!open)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <NavLink className="navbar-brand" to="/homescreen">
          <img
            src={process.env.PUBLIC_URL + '/Media/Group 33.png'}
            alt="Logo"
            className="mr-auto img-fluid"
            style={{ objectFit: 'contain' }}
          />
        </NavLink>

        <div
          className="collapse w-50 offcanvas offcanvas-start navbar-collapse"
          id="offcanvasstart"
        >
          <ul className="navbar-nav mx-auto text-poppins">
            <li className="nav-item">
              <NavLink className="nav-link" activeclassname="active" exact to="/homescreen">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <CDropdown>
                <CDropdownToggle className="nav-link" color="white">
                  Services
                </CDropdownToggle>
                <CDropdownMenu>
                  <NavLink className="nav-link" activeclassname="active" to="/insurancescreen">
                    <CDropdownItem>Insurance</CDropdownItem>
                  </NavLink>
                  <NavLink className="nav-link" activeclassname="active" to="/properties">
                    <CDropdownItem color="white">Properties</CDropdownItem>
                  </NavLink>
                  <NavLink className="nav-link" activeclassname="active" to="/loanscreen">
                    <CDropdownItem>Loans</CDropdownItem>
                  </NavLink>
                  <NavLink className="nav-link" activeclassname="active" to="/installment">
                    <CDropdownItem>Installment</CDropdownItem>
                  </NavLink>
                </CDropdownMenu>
              </CDropdown>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeclassname="active" exact to="/aboutscreen">
                About Us
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" activeclassname="active" to="/blogscreen">
                Blog
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="d-flex flex-wrap align-items-center d-none d-md-flex">
          <NavLink
            to={'/register'}
            type="button"
            className="btn btn-primary fs-sm fw-sm color-white rounded-pill ml-2 d-xs-none d-lg-block d-xl-block"
          >
            Register Complaint
          </NavLink>

          <NavLink
            to={'/login'}
            type="button"
            style={{
              color: '#0C2543',
              fontFamily: 'Poppins-SemiBold',
              border: '1px solid #0C2543',
            }}
            className="btn  rounded-pill ml-2 "
          >
            Login
          </NavLink>
          <NavLink
            to={'/login'}
            type="button"
            style={{
              color: '#0C2543',
              fontFamily: 'Poppins-SemiBold',
              border: '1px solid #0C2543',
            }}
            className="btn  rounded-pill ml-2 "
          >
            Register with us
          </NavLink>
        </div>
      </nav>
    </div>
  )
}

export default Header
