import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
  CFormInput,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import { useDispatch } from 'react-redux'
import { postCommonForm, postInsuranceForm, postPropertyForm } from 'src/store/action'
import { useNavigate, useNavigation } from 'react-router-dom'
import { uploadImagesToS3 } from 'src/utils/imagesUpload'

const initialState = {
  name: '',
  purpose: '',
  duration: '',
  typeOfProject: '',
  plotSize: '',
  plotStage: '',
  possessionType: '',
  otherDetails: '',
  specificDetails: '',
  typeOfProperty: '',
  areaSize: '',
  price: 0,
  readyForPossession: '',
  advanceAmount: '',
  noOfInstallment: 0,
  monthlyInstallment: 0,
  builtInYear: 0,
  flooring: '',
  floors: 0,
  parkingSpace: '',
  electricityBackup: '',
  powerSupply: '',
  furnished: '',
  view: '',
  wasteDisposal: '',
  otherMainFeatures: '',
  bedRooms: '',
  otherBedRooms: '',
  bathrooms: '',
  kitchens: '',
  storeRooms: '',
  drawingRooms: '',
  diningRooms: '',
  studyRooms: '',
  prayerRooms: '',
  servantQuarters: '',
  sittingRooms: '',
  communityLawn: '',
  medicalCentre: '',
  dayCare: '',
  communityPool: '',
  kidsPlayArea: '',
  mosque: '',
  communityGym: '',
  bbqArea: '',
  communityCentre: '',
  otherCommunityFacilities: '',
  nearBySchools: '',
  nearByHospitals: '',
  nearByShoppingMalls: '',
  nearByColleges: '',
  nearByRestaurants: '',
  nearByPublicTransport: '',
  nearByUniversity: '',
  distanceFromAirport: '',
  otherNearByPlaces: '',
  adTitle: '',
  adDescription: '',
  images: [],
  fullName: '',
  mobile: '',
  landLine: '',
  anyMessage: '',
  email: '',
  size: '',
  commonForm: {
    name: '',
    email: '',
    number: '',
    whatsApp: '',
    cnic: '',
    city: '',
    area: '',
    reference: '',
    interCode: '',
    typeOfInquiry: [],
  },
}
const PropertyForm = () => {
  const navigate = useNavigate()
  const handleCommonInputChange = (e) => {
    const { name, value } = e.target
    setFinalState((prev) => ({
      ...prev,
      commonForm: {
        ...prev.commonForm,
        [name]: value,
      },
    }))
  }
  const [isProject, setIsProject] = useState(false)
  const [isInstallment, setIsInstallment] = useState(false)
  const [finalState, setFinalState] = useState(initialState)
  const [step, setStep] = useState(0) // State to keep track of the current step
  const [dropdownCount, setDropdownCount] = useState(1)
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFinalState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    if (
      finalState.commonForm.name === '' ||
      finalState.commonForm.email === '' ||
      finalState.commonForm.number === '' ||
      finalState.commonForm.whatsApp === '' ||
      finalState.commonForm.cnic === '' ||
      finalState?.commonForm?.typeOfInquiry.length <= 0 ||
      finalState?.commonForm?.typeOfInquiry[dropdownCount - 1] == undefined
    ) {
      alert('Please fill all the fields')
    } else {
      dispatch(postPropertyForm(finalState, setLoading, navigate))
    }
  }
  const goPrevious = () => {
    setStep(step - 1)
  }
  const InquiryType = ['FaceBook', 'WhatsApp', 'Instagram', 'Google Ads', 'Local']
  const renderDropDown = () => {
    return (
      <div>
        {Array.from({ length: dropdownCount }).map((_, index) => {
          return (
            <div
              key={index}
              style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}
            >
              <CDropdown style={{ minWidth: '200px', maxWidth: '300px' }}>
                <CDropdownToggle color="light" caret>
                  {finalState?.commonForm?.typeOfInquiry[index]?.inquiry || 'Select Inquiry Type'}
                </CDropdownToggle>
                <CDropdownMenu>
                  {InquiryType.map((item, idx) => (
                    <CDropdownItem
                      key={idx}
                      onClick={() => {
                        setFinalState((prev) => {
                          const updatedTypeOfInquiry = [...prev.commonForm.typeOfInquiry]
                          updatedTypeOfInquiry[index] = {
                            ...updatedTypeOfInquiry[index],
                            inquiry: item,
                          }

                          return {
                            ...prev,
                            commonForm: {
                              ...prev.commonForm,
                              typeOfInquiry: updatedTypeOfInquiry,
                            },
                          }
                        })
                      }}
                    >
                      {item}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>

              <CFormInput
                placeholder="Enter the comments"
                style={{ width: '300px' }}
                value={finalState?.commonForm?.typeOfInquiry[index]?.comment || ''}
                onChange={(e) => {
                  setFinalState((prev) => {
                    const updatedTypeOfInquiry = [...prev.commonForm.typeOfInquiry]
                    updatedTypeOfInquiry[index] = {
                      ...updatedTypeOfInquiry[index],
                      comment: e.target.value,
                    }

                    return {
                      ...prev,
                      commonForm: {
                        ...prev.commonForm,
                        typeOfInquiry: updatedTypeOfInquiry,
                      },
                    }
                  })
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }
  const typeOfProject = ['Home', 'Plots', 'Commercial', 'Industrial']
  const home = ['Home', 'Flats', 'Lower Portion', 'Upper Portion', 'Farm House', 'Room']
  const plots = [
    'Agriculture Plot',
    'Commercial Plot',
    'Residential Plot',
    'Plot File',
    'Industrial Land',
  ]
  const commercial = ['Office', 'Shop', 'WareHouse', 'Factory', 'Building', 'Other']
  const stage = [
    'Planning',
    'Ground',
    'Infrastructure',
    'Under-Construction',
    'Under-Developed',
    'Developed',
    'Renovation',
  ]
  const flooring = ['Tile', 'Marble', 'Wooden', 'Chip', 'Cement', 'Other']
  const electricityBackup = ['N/A', 'Generator', 'UPS', 'Solar', 'Main Line', 'Other']
  const powerSupply = ['1 Phase', '2 Phase', '3 Phase', 'N/A']
  const handleFileChange = async (event) => {
    const files = event.target.files
    if (files.length > 0) {
      try {
        const result = await uploadImagesToS3(Array.from(files))
        console.log('pathhhh======    ', result[0].Location)

        setFinalState((prev) => ({
          ...prev,
          images: [...prev.images, result[0].Location],
        }))
      } catch (error) {
        console.error('Error uploading images:', error)
      }
    }
  }
  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <div>
              <h1 className="mb-4">Common Form</h1>
              <h5 className="mb-4">Name</h5>
              <CFormInput
                name="name"
                value={finalState?.commonForm?.name}
                onChange={handleCommonInputChange}
              />
              <div className="mb-4" style={{ marginTop: 10 }}>
                <h5 className="mb-4">Email</h5>
                <CFormInput
                  name="email"
                  value={finalState?.commonForm?.email}
                  onChange={handleCommonInputChange}
                />
              </div>
              <div className="mb-4" style={{ marginTop: 10 }}>
                <h5 className="mb-4">Number</h5>
                <CFormInput
                  name="number"
                  value={finalState?.commonForm?.number}
                  onChange={handleCommonInputChange}
                />
              </div>
              <div className="mb-4" style={{ marginTop: 10 }}>
                <h5 className="mb-4">WhatsApp Number</h5>
                <CFormInput
                  name="whatsApp"
                  value={finalState?.commonForm?.whatsApp}
                  onChange={handleCommonInputChange}
                />
              </div>
              <div className="mb-4" style={{ marginTop: 10 }}>
                <h5 className="mb-4">CNIC #</h5>
                <CFormInput
                  name="cnic"
                  value={finalState?.commonForm?.cnic}
                  onChange={handleCommonInputChange}
                />
              </div>
            </div>
            <div>
              <h5 className="mb-4">City</h5>
              <CFormInput
                name="city"
                value={finalState?.commonForm?.city}
                onChange={handleCommonInputChange}
              />
              <div className="mb-4" style={{ marginTop: 10 }}>
                <h5 className="mb-4">Area</h5>
                <CFormInput
                  name="area"
                  value={finalState?.commonForm?.area}
                  onChange={handleCommonInputChange}
                />
              </div>
              <div className="mb-4" style={{ marginTop: 10 }}>
                <h5 className="mb-4">Referrence</h5>
                <CFormInput
                  name="reference"
                  value={finalState?.commonForm?.reference}
                  onChange={handleCommonInputChange}
                />
              </div>
              <div className="mb-4" style={{ marginTop: 10 }}>
                <h5 className="mb-4">Inter Code</h5>
                <CFormInput
                  name="interCode"
                  value={finalState?.commonForm?.interCode}
                  onChange={handleCommonInputChange}
                />
              </div>
            </div>
            <div className="mb-4">
              <h5 className="mb-4">Inquiry Type</h5>
              <CCardBody className="p-4">{renderDropDown()}</CCardBody>
              <div className="d-flex justify-content-between">
                <CButton
                  color="primary"
                  onClick={() => {
                    if (
                      finalState?.commonForm?.typeOfInquiry.length <= 0 ||
                      finalState?.commonForm?.typeOfInquiry[dropdownCount - 1] == undefined
                    ) {
                      console.log(
                        'fieldddddd ',
                        finalState?.commonForm?.typeOfInquiry[dropdownCount - 1],
                      )

                      alert('Please fill the already fields before going to a new one')
                    } else {
                      if (
                        finalState?.commonForm?.typeOfInquiry[dropdownCount - 1]?.inquiry &&
                        finalState?.commonForm?.typeOfInquiry[dropdownCount - 1]?.comment
                      ) {
                        setDropdownCount((prev) => prev + 1)
                      } else {
                        alert('Please fill the already fields before going to a new one')
                      }
                    }
                  }}
                >
                  Add Field
                </CButton>
              </div>
              <div style={{ marginTop: 10 }} className="d-flex justify-content-between">
                <CButton color="primary" onClick={() => setStep(step + 1)}>
                  Next
                </CButton>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div>
            <div className="container my-3">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <h1 className="mb-4" style={{ color: 'blue' }}>
                  Property Partner Form
                </h1>
              </div>
              <h3>Location and Purpose</h3>
              <div>
                <h5>Select Purpose</h5>
                <CDropdown style={{ width: '30%' }}>
                  <CDropdownToggle color="light" caret>
                    {finalState.purpose || 'Select'}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem
                      onClick={() => {
                        setFinalState((prev) => {
                          return {
                            ...prev,
                            purpose: 'Sale',
                          }
                        })
                      }}
                    >
                      Sale
                    </CDropdownItem>
                    <CDropdownItem
                      onClick={() => {
                        setFinalState((prev) => {
                          return {
                            ...prev,
                            purpose: 'Rent',
                          }
                        })
                      }}
                    >
                      Rent
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
                <div className="d-flex">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isProject}
                      id="flexCheckDefault"
                      onChange={() => setIsProject(!isProject)}
                    />
                  </div>
                  <h5>Project Information</h5>
                </div>

                {isProject && (
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <div style={{ width: '30%' }}>
                      <h6>Name Of Project</h6>
                      <CFormInput
                        name="name"
                        value={finalState.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div style={{ width: '30%' }}>
                      <h6>Project Duration</h6>
                      <CFormInput
                        name="duration"
                        value={finalState.duration}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div style={{ width: '30%' }}>
                      <h6>Type Of Project</h6>
                      <CDropdown style={{ width: '100%' }}>
                        <CDropdownToggle color="light" caret>
                          {finalState.typeOfProject || 'Select'}
                        </CDropdownToggle>
                        <CDropdownMenu>
                          {typeOfProject.map((item, index) => {
                            return (
                              <CDropdownItem
                                key={index}
                                onClick={() => {
                                  setFinalState((prev) => {
                                    return {
                                      ...prev,
                                      typeOfProject: item,
                                    }
                                  })
                                }}
                              >
                                {item}
                              </CDropdownItem>
                            )
                          })}
                        </CDropdownMenu>
                      </CDropdown>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '30%' }}>
                  <h6>Plotting Size</h6>
                  <CFormInput
                    name="plotSize"
                    value={finalState.plotSize}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ width: '30%' }}>
                  <h6>Stage</h6>
                  <CDropdown style={{ width: '100%' }}>
                    <CDropdownToggle color="light" caret>
                      {finalState.plotStage || 'Select'}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {stage.map((item, index) => {
                        return (
                          <CDropdownItem
                            key={index}
                            onClick={() => {
                              setFinalState((prev) => {
                                return {
                                  ...prev,
                                  plotStage: item,
                                }
                              })
                            }}
                          >
                            {item}
                          </CDropdownItem>
                        )
                      })}
                    </CDropdownMenu>
                  </CDropdown>
                </div>
                <div style={{ width: '30%' }}>
                  <h6>Possession Type</h6>
                  <CDropdown style={{ width: '100%' }}>
                    <CDropdownToggle color="light" caret>
                      {finalState.possessionType || 'Select'}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              possessionType: 'Registry',
                            }
                          })
                        }}
                      >
                        Registry
                      </CDropdownItem>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              possessionType: 'File',
                            }
                          })
                        }}
                      >
                        File
                      </CDropdownItem>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              possessionType: 'Other',
                            }
                          })
                        }}
                      >
                        Other
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>
              </div>
              <div>
                <h6>Other Details</h6>
                <CFormInput
                  name="otherDetails"
                  value={finalState.otherDetails}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h6>Any Specific Details</h6>
                <CFormInput
                  name="specificDetails"
                  value={finalState.specificDetails}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h6>Select Property Type</h6>
                <CDropdown style={{ width: '30%' }}>
                  <CDropdownToggle color="light" caret>
                    {finalState.typeOfProperty || 'Select'}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    {finalState.typeOfProject == 'Home'
                      ? home.map((item, index) => {
                          return (
                            <CDropdownItem
                              key={index}
                              onClick={() => {
                                setFinalState((prev) => {
                                  return {
                                    ...prev,
                                    typeOfProperty: item,
                                  }
                                })
                              }}
                            >
                              {item}
                            </CDropdownItem>
                          )
                        })
                      : finalState.typeOfProject == 'Plots'
                      ? plots.map((item, index) => {
                          return (
                            <CDropdownItem
                              key={index}
                              onClick={() => {
                                setFinalState((prev) => {
                                  return {
                                    ...prev,
                                    typeOfProperty: item,
                                  }
                                })
                              }}
                            >
                              {item}
                            </CDropdownItem>
                          )
                        })
                      : commercial.map((item, index) => {
                          return (
                            <CDropdownItem
                              key={index}
                              onClick={() => {
                                setFinalState((prev) => {
                                  return {
                                    ...prev,
                                    typeOfProperty: item,
                                  }
                                })
                              }}
                            >
                              {item}
                            </CDropdownItem>
                          )
                        })}
                  </CDropdownMenu>
                </CDropdown>
              </div>
            </div>

            <div className="container my-3">
              <h3>Price and Area</h3>
              <div>
                <h6>Area</h6>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <CFormInput
                    style={{ width: '50%' }}
                    name="areaSize"
                    value={finalState.areaSize}
                    onChange={handleInputChange}
                  />
                  <CDropdown style={{ width: '50%' }}>
                    <CDropdownToggle color="light" caret>
                      {finalState.size || 'Select'}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              size: 'Marla',
                            }
                          })
                        }}
                      >
                        Marla
                      </CDropdownItem>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              size: 'Sq. Ft',
                            }
                          })
                        }}
                      >
                        Sq. Ft
                      </CDropdownItem>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              size: 'Sq. M',
                            }
                          })
                        }}
                      >
                        Sq. M
                      </CDropdownItem>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              size: 'Sq. Yd',
                            }
                          })
                        }}
                      >
                        Sq. Yd
                      </CDropdownItem>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              size: 'Kanal',
                            }
                          })
                        }}
                      >
                        Kanal
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <h6 style={{ width: '50%' }}>Price</h6>
                  <CFormInput
                    style={{ width: '50%' }}
                    name="price"
                    value={finalState.price}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <h6 style={{ width: '50%' }}>Ready For Possession</h6>
                  <CDropdown style={{ width: '50%' }}>
                    <CDropdownToggle color="light" caret>
                      {finalState.readyForPossession || 'Select'}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              readyForPossession: 'Yes',
                            }
                          })
                        }}
                      >
                        Yes
                      </CDropdownItem>
                      <CDropdownItem
                        onClick={() => {
                          setFinalState((prev) => {
                            return {
                              ...prev,
                              readyForPossession: 'No',
                            }
                          })
                        }}
                      >
                        No
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </div>
                <div className="d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isInstallment}
                      id="flexCheckDefault"
                      onChange={() => setIsInstallment(!isInstallment)}
                    />
                  </div>
                  <h3>Installment Available</h3>
                </div>
                {isInstallment && (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <h6 style={{ width: '50%' }}>Advance Amount</h6>
                      <CDropdown style={{ width: '50%' }}>
                        <CDropdownToggle color="light" caret>
                          {finalState.advanceAmount || 'Select'}
                        </CDropdownToggle>
                        <CDropdownMenu>
                          <CDropdownItem
                            onClick={() => {
                              setFinalState((prev) => {
                                return {
                                  ...prev,
                                  advanceAmount: 'Yes',
                                }
                              })
                            }}
                          >
                            Yes
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={() => {
                              setFinalState((prev) => {
                                return {
                                  ...prev,
                                  advanceAmount: 'No',
                                }
                              })
                            }}
                          >
                            No
                          </CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <h6 style={{ width: '50%' }}>No of Installment</h6>
                      <CFormInput
                        style={{ width: '50%' }}
                        name="noOfInstallment"
                        value={finalState.noOfInstallment}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <h6 style={{ width: '50%' }}>Monthly Installments</h6>
                      <CFormInput
                        style={{ width: '50%' }}
                        name="monthlyInstallment"
                        value={finalState.monthlyInstallment}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div>
              <h3>Feature and Amenities</h3>
              <div className="container my-3">
                <h3>Main Features</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Floors</h6>
                    <CFormInput
                      name="floors"
                      value={finalState.floors}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Flooring</h6>
                    <CDropdown style={{ width: '100%' }}>
                      <CDropdownToggle color="light" caret>
                        {finalState.flooring || 'Select'}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        {flooring.map((item, index) => {
                          return (
                            <CDropdownItem
                              key={index}
                              onClick={() => {
                                setFinalState((prev) => {
                                  return {
                                    ...prev,
                                    flooring: item,
                                  }
                                })
                              }}
                            >
                              {item}
                            </CDropdownItem>
                          )
                        })}
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Built in Year</h6>
                    <CFormInput
                      name="builtInYear"
                      value={finalState.builtInYear}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Parking Spaces</h6>
                    <CFormInput
                      name="parkingSpace"
                      value={finalState.parkingSpace}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Electricity Backup</h6>
                    <CDropdown style={{ width: '100%' }}>
                      <CDropdownToggle color="light" caret>
                        {finalState.electricityBackup || 'Select'}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        {electricityBackup.map((item, index) => {
                          return (
                            <CDropdownItem
                              key={index}
                              onClick={() => {
                                setFinalState((prev) => {
                                  return {
                                    ...prev,
                                    electricityBackup: item,
                                  }
                                })
                              }}
                            >
                              {item}
                            </CDropdownItem>
                          )
                        })}
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Power Supply</h6>
                    <CDropdown style={{ width: '100%' }}>
                      <CDropdownToggle color="light" caret>
                        {finalState.powerSupply || 'Select'}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        {powerSupply.map((item, index) => {
                          return (
                            <CDropdownItem
                              key={index}
                              onClick={() => {
                                setFinalState((prev) => {
                                  return {
                                    ...prev,
                                    powerSupply: item,
                                  }
                                })
                              }}
                            >
                              {item}
                            </CDropdownItem>
                          )
                        })}
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Furnished</h6>
                    <CDropdown style={{ width: '100%' }}>
                      <CDropdownToggle color="light" caret>
                        {finalState.furnished || 'Select'}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <CDropdownItem
                          onClick={() => {
                            setFinalState((prev) => {
                              return {
                                ...prev,
                                furnished: 'Yes',
                              }
                            })
                          }}
                        >
                          Yes
                        </CDropdownItem>
                        <CDropdownItem
                          onClick={() => {
                            setFinalState((prev) => {
                              return {
                                ...prev,
                                furnished: 'No',
                              }
                            })
                          }}
                        >
                          No
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>View</h6>
                    <CFormInput name="view" value={finalState.view} onChange={handleInputChange} />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Waste Disposal</h6>
                    <CFormInput
                      name="wasteDisposal"
                      value={finalState.wasteDisposal}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div>
                  <h6>Other Main Features</h6>
                  <CFormInput
                    name="otherMainFeatures"
                    value={finalState.otherMainFeatures}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="container my-3">
                <h3>Rooms</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>BedRooms</h6>
                    <CFormInput
                      name="bedRooms"
                      value={finalState.bedRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Other Rooms</h6>
                    <CFormInput
                      name="otherBedRooms"
                      value={finalState.otherBedRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Bath Rooms</h6>
                    <CFormInput
                      name="bathrooms"
                      value={finalState.bathrooms}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Kitchens</h6>
                    <CFormInput
                      name="kitchens"
                      value={finalState.kitchens}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Store Rooms</h6>
                    <CFormInput
                      name="storeRooms"
                      value={finalState.storeRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Drawing Room</h6>
                    <CFormInput
                      name="drawingRooms"
                      value={finalState.drawingRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Dinning Room</h6>
                    <CFormInput
                      name="diningRooms"
                      value={finalState.diningRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Study Room</h6>
                    <CFormInput
                      name="studyRooms"
                      value={finalState.studyRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Prayer Room</h6>
                    <CFormInput
                      name="prayerRooms"
                      value={finalState.prayerRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Server Quarters</h6>
                    <CFormInput
                      name="servantQuarters"
                      value={finalState.servantQuarters}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '60%' }}>
                    <h6>Lounge or Sitting Room</h6>
                    <CFormInput
                      name="sittingRooms"
                      value={finalState.sittingRooms}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="container my-3">
                <h3>Community Features</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Community Lawn or Garden</h6>
                    <CFormInput
                      name="communityLawn"
                      value={finalState.communityLawn}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>First Aid or Medical Centre</h6>
                    <CFormInput
                      name="medicalCentre"
                      value={finalState.medicalCentre}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Day Care Centre</h6>
                    <CFormInput
                      name="dayCare"
                      value={finalState.dayCare}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Community Swimming Pool</h6>
                    <CFormInput
                      name="communityPool"
                      value={finalState.communityPool}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Kids Play Area</h6>
                    <CFormInput
                      name="kidsPlayArea"
                      value={finalState.kidsPlayArea}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Mosque</h6>
                    <CFormInput
                      name="mosque"
                      value={finalState.mosque}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>Community Gym</h6>
                    <CFormInput
                      name="communityGym"
                      value={finalState.communityGym}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Barbeque Area</h6>
                    <CFormInput
                      name="bbqArea"
                      value={finalState.bbqArea}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Community Centre</h6>
                    <CFormInput
                      name="communityCentre"
                      value={finalState.communityCentre}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div>
                  <h6>Other Community Facilities</h6>
                  <CFormInput
                    name="otherCommunityFacilities"
                    value={finalState.otherCommunityFacilities}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="container my-3">
                <h3>NearBy Locations & Other Facilities</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>NearBy Schools</h6>
                    <CFormInput
                      name="nearBySchools"
                      value={finalState.nearBySchools}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>NearBy Hospitals</h6>
                    <CFormInput
                      name="nearByHospitals"
                      value={finalState.nearByHospitals}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>NearBy Shopping Malls</h6>
                    <CFormInput
                      name="nearByShoppingMalls"
                      value={finalState.nearByShoppingMalls}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>NearBy College</h6>
                    <CFormInput
                      name="nearByColleges"
                      value={finalState.nearByColleges}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>NearBy Restaurants</h6>
                    <CFormInput
                      name="nearByRestaurants"
                      value={finalState.nearByRestaurants}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>NearBy Public Transport Service</h6>
                    <CFormInput
                      name="nearByPublicTransport"
                      value={finalState.nearByPublicTransport}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '30%' }}>
                    <h6>NearBy University</h6>
                    <CFormInput
                      name="nearByUniversity"
                      value={finalState.nearByUniversity}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Distance From Airport (kms)</h6>
                    <CFormInput
                      name="distanceFromAirport"
                      value={finalState.distanceFromAirport}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div style={{ width: '30%' }}>
                    <h6>Other NearBy Places</h6>
                    <CFormInput
                      name="otherNearByPlaces"
                      value={finalState.otherNearByPlaces}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="container my-3">
              <h3>Ad Information</h3>
              <div>
                <h4>Title</h4>
                <CFormInput
                  name="adTitle"
                  value={finalState.adTitle}
                  onChange={handleInputChange}
                />
                <h4>Description</h4>
                <CFormInput
                  name="adDescription"
                  value={finalState.adDescription}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="container my-3">
              <h3 className="mb-4">Property Images and Videos</h3>
              <CFormInput
                readOnly
                title="Send video to this number"
                name=""
                value={'03293266662'}
              />
              <div>
                {/* Container for images */}
                <div className="row mb-3">
                  {finalState.images.length > 0 ? (
                    finalState.images.map((item, index) => (
                      <div className="col-6 col-md-4 col-lg-3 mb-3" key={index}>
                        <img
                          src={item}
                          alt={`property-${index}`}
                          className="img-fluid rounded border"
                        />
                      </div>
                    ))
                  ) : (
                    <p>No images uploaded</p>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="image" className="form-label">
                    Property image
                  </label>
                  <CFormInput
                    type="file"
                    id="image"
                    placeholder="Choose image"
                    accept="image/*"
                    onChange={handleFileChange}
                    multiple
                    required
                  />
                </div>
              </div>
            </div>

            <div className="container my-3">
              <h3>Contact Information</h3>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%' }}>
                  <h6>Full Name</h6>
                  <CFormInput
                    name="fullName"
                    value={finalState.fullName}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <h6>Mobile</h6>
                  <CFormInput
                    name="mobile"
                    value={finalState.mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%' }}>
                  <h6>LandLine</h6>
                  <CFormInput
                    name="landLine"
                    value={finalState.landLine}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <h6>Email</h6>
                  <CFormInput name="email" value={finalState.email} onChange={handleInputChange} />
                </div>
              </div>
              <div>
                <h6>Any Message:</h6>
                <CFormInput
                  name="anyMessage"
                  value={finalState.anyMessage}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex justify-content-between">
                <div style={{ marginTop: 10 }} className="d-flex justify-content-between">
                  <CButton color="primary" onClick={goPrevious}>
                    Previous
                  </CButton>
                </div>
                <div style={{ marginTop: 10 }} className="d-flex justify-content-between">
                  <CButton color="primary" onClick={handleSubmit}>
                    Submit
                  </CButton>
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center mt-5 mb-5">
          <CCol md={14} lg={12} xl={11}>
            <CCard className="mx-4">
              <CCardBody className="p-4">{renderStep()}</CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default PropertyForm
