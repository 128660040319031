import { CSpinner } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import Header from 'src/components/Clientside/Header'
import { getBlog } from 'src/store/action'

const PublicBlogDetail = () => {
  const { state } = useLocation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    convertStringToHtml(state.descripition)
    setLoading(false)
  }, [state.descripition])

  const convertStringToHtml = (string) => {
    const parser = new DOMParser()
    const documents = parser.parseFromString(string, 'text/html')
    const elements = documents.querySelectorAll('body > *')
    const renderHtmlElement = document.getElementById('render-html')
    console.log('find ele')
    if (renderHtmlElement) {
      console.log('mil gaya')
      renderHtmlElement.innerHTML = '' // Clear existing content

      for (let i = 0; i < elements.length; i++) {
        console.log('elment', elements[i])
        renderHtmlElement.appendChild(elements[i].cloneNode(true))
      }
    } else {
      console.log('nahi mila')
    }
  }

  return (
    <>
      <Header />
      {loading ? (
        <div className="d-flex justify-content-center my-5">
          <CSpinner style={{ width: '20px', height: '20px' }} />
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center w-75 mx-auto">
          <div className="my-0 ml-5 text-left w-100">
            <h1 className="text-black">{state?.title}</h1>
          </div>
          <div className="w-full mx-auto p-5" id="render-html">
            {/* {convertStringToHtml(blogData.descripition)} */}
          </div>
        </div>
      )}
    </>
  )
}

export default PublicBlogDetail
