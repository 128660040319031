import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
  CFormInput,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import { useDispatch } from 'react-redux'
import { postCommonForm, postInsuranceForm } from 'src/store/action'
import { useNavigate, useNavigation } from 'react-router-dom'

const CommonForm = () => {
  const navigate = useNavigate()
  const initialState = {
    name: '',
    email: '',
    number: '',
    whatsApp: '',
    cnic: '',
    city: '',
    area: '',
    reference: '',
    interCode: '',
    typeOfInquiry: [],
  }

  const [finalState, setFinalState] = useState(initialState)
  const [step, setStep] = useState(1) // State to keep track of the current step
  const [dropdownCount, setDropdownCount] = useState(1)
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFinalState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    if (step === 1) {
      if (
        finalState.name === '' ||
        finalState.email === '' ||
        finalState.number === '' ||
        finalState.whatsApp === '' ||
        finalState.cnic === ''
      ) {
        alert('Please fill all the fields')
      } else {
        setStep((prevStep) => prevStep + 1)
      }
    } else if (step === 2) {
      if (
        finalState.city === '' ||
        finalState.area === '' ||
        finalState.reference === '' ||
        finalState.interCode === ''
      ) {
        alert('Please fill all the fields')
      } else {
        setStep((prevStep) => prevStep + 1)
      }
    } else if (step === 3) {
      if (
        finalState?.typeOfInquiry.length <= 0 ||
        finalState?.typeOfInquiry[dropdownCount - 1] == undefined
      ) {
        console.log('fieldddddd ', finalState?.typeOfInquiry[dropdownCount - 1])

        alert('Please fill all the fields')
      } else {
        if (
          finalState?.typeOfInquiry[dropdownCount - 1]?.inquiry &&
          finalState?.typeOfInquiry[dropdownCount - 1]?.comment
        ) {
          dispatch(postCommonForm(finalState, setLoading, navigate))
        } else {
          alert('Please fill all the fields')
        }
      }
    }
  }
  const InquiryType = ['FaceBook', 'WhatsApp', 'Instagram', 'Google Ads', 'Local']
  const renderDropDown = () => {
    return (
      <div>
        {Array.from({ length: dropdownCount }).map((_, index) => {
          return (
            <div
              key={index}
              style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}
            >
              <CDropdown style={{ minWidth: '200px', maxWidth: '300px' }}>
                <CDropdownToggle color="light" caret>
                  {finalState.typeOfInquiry[index]?.inquiry || 'Select Inquiry Type'}
                </CDropdownToggle>
                <CDropdownMenu>
                  {InquiryType.map((item, idx) => (
                    <CDropdownItem
                      key={idx}
                      onClick={() => {
                        setFinalState((prev) => {
                          const updatedTypeOfInquiry = [...prev.typeOfInquiry]
                          updatedTypeOfInquiry[index] = {
                            ...updatedTypeOfInquiry[index],
                            inquiry: item,
                          }

                          return {
                            ...prev,
                            typeOfInquiry: updatedTypeOfInquiry,
                          }
                        })
                      }}
                    >
                      {item}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>

              <CFormInput
                placeholder="Enter the comments"
                style={{ width: '300px' }}
                value={finalState.typeOfInquiry[index]?.comment || ''}
                onChange={(e) => {
                  setFinalState((prev) => {
                    const updatedTypeOfInquiry = [...prev.typeOfInquiry]
                    updatedTypeOfInquiry[index] = {
                      ...updatedTypeOfInquiry[index],
                      comment: e.target.value,
                    }

                    return {
                      ...prev,
                      typeOfInquiry: updatedTypeOfInquiry,
                    }
                  })
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <h1 className="mb-4">Common Form</h1>
            <h5 className="mb-4">Name</h5>
            <CFormInput name="name" value={finalState.name} onChange={handleInputChange} />
            <div className="mb-4" style={{ marginTop: 10 }}>
              <h5 className="mb-4">Email</h5>
              <CFormInput name="email" value={finalState.email} onChange={handleInputChange} />
            </div>
            <div className="mb-4" style={{ marginTop: 10 }}>
              <h5 className="mb-4">Number</h5>
              <CFormInput name="number" value={finalState.number} onChange={handleInputChange} />
            </div>
            <div className="mb-4" style={{ marginTop: 10 }}>
              <h5 className="mb-4">WhatsApp Number</h5>
              <CFormInput
                name="whatsApp"
                value={finalState.whatsApp}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4" style={{ marginTop: 10 }}>
              <h5 className="mb-4">CNIC #</h5>
              <CFormInput name="cnic" value={finalState.cnic} onChange={handleInputChange} />
            </div>
            <div className="d-flex justify-content-between">
              <CButton color="primary" onClick={handleSubmit}>
                Next
              </CButton>
            </div>
          </div>
        )
      case 2:
        return (
          <div>
            <h5 className="mb-4">City</h5>
            <CFormInput name="city" value={finalState.city} onChange={handleInputChange} />
            <div className="mb-4" style={{ marginTop: 10 }}>
              <h5 className="mb-4">Area</h5>
              <CFormInput name="area" value={finalState.area} onChange={handleInputChange} />
            </div>
            <div className="mb-4" style={{ marginTop: 10 }}>
              <h5 className="mb-4">Referrence</h5>
              <CFormInput
                name="reference"
                value={finalState.reference}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4" style={{ marginTop: 10 }}>
              <h5 className="mb-4">Inter Code</h5>
              <CFormInput
                name="interCode"
                value={finalState.interCode}
                onChange={handleInputChange}
              />
            </div>
            <div className="d-flex justify-content-between">
              <CButton color="primary" onClick={handleSubmit}>
                Next
              </CButton>
            </div>
          </div>
        )
      case 3:
        return (
          <div className="mb-4">
            <h5 className="mb-4">Inquiry Type</h5>
            <CCardBody className="p-4">{renderDropDown()}</CCardBody>
            <div className="d-flex justify-content-between">
              <CButton
                color="primary"
                onClick={() => {
                  if (
                    finalState?.typeOfInquiry.length <= 0 ||
                    finalState?.typeOfInquiry[dropdownCount - 1] == undefined
                  ) {
                    console.log('fieldddddd ', finalState?.typeOfInquiry[dropdownCount - 1])

                    alert('Please fill the already fields before going to a new one')
                  } else {
                    if (
                      finalState?.typeOfInquiry[dropdownCount - 1]?.inquiry &&
                      finalState?.typeOfInquiry[dropdownCount - 1]?.comment
                    ) {
                      setDropdownCount((prev) => prev + 1)
                    } else {
                      alert('Please fill the already fields before going to a new one')
                    }
                  }
                }}
              >
                Add Field
              </CButton>
            </div>
            <div style={{ marginTop: 5 }} className="d-flex justify-content-between">
              <CButton color="primary" onClick={handleSubmit}>
                Next
              </CButton>
            </div>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center mt-5 mb-5">
          <CCol md={14} lg={12} xl={11}>
            <CCard className="mx-4">
              <CCardBody className="p-4">{renderStep()}</CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default CommonForm
