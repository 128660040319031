import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { agentData } from 'src/utils/constants'

const MadadghaarAgent = ({ title }) => {
  const [activeCardIndex, setActiveCardIndex] = useState(0)

  const handleNextClick = (e) => {
    e.preventDefault()
    setActiveCardIndex((prevIndex) => (prevIndex + 1) % numberOfCards)
  }

  const numberOfCards = agentData.length
  return (
    <>
      <div style={{ backgroundColor: '#F0F4FD' }} className="d-none d-md-flex">
        <div className="container pt-5 pb-5">
          <h1
            className="text-center mb-5"
            style={{
              fontFamily: 'Nunito',
              fontSize: '50',
              fontWeight: '700',
              color: '#000000',
            }}
          >
            {title}
          </h1>
          <div className="card-deck">
            {agentData.map((item, index) => (
              <div className="card p-3" style={{ borderRadius: '15px' }} key={index}>
                <img
                  style={{ borderRadius: '15px' }}
                  src={process.env.PUBLIC_URL + item.icon}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      fontFamily: 'Nunito',
                      fontSize: '24',
                      fontWeight: '700',
                      color: '#222222',
                    }}
                  >
                    {item.title}
                  </h5>
                  <p className="card-text small" style={{ color: '#444444' }}>
                    {item.description.split('\n').map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Carousel Cards */}
      <div className="container-fluid d-md-none p-3">
        <div className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            {agentData.map((item, index) => (
              <div
                key={index}
                className={`carousel-item ${index === activeCardIndex ? 'active' : ''}`}
              >
                <div style={{ backgroundColor: '#F0F4FD' }}>
                  <div className="container pt-5 pb-5">
                    <h1
                      className="text-center mb-5"
                      style={{
                        fontFamily: 'Nunito',
                        fontSize: '50px',
                        fontWeight: '700',
                        color: '#000000',
                      }}
                    >
                      {title}
                    </h1>
                    <div className="card-deck">
                      <div className="card p-3" style={{ borderRadius: '15px' }}>
                        <img
                          style={{ borderRadius: '15px' }}
                          src={process.env.PUBLIC_URL + item.icon}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{
                              fontFamily: 'Nunito',
                              fontSize: '24px',
                              fontWeight: '700',
                              color: '#222222',
                            }}
                          >
                            {item.title}
                          </h5>
                          <p className="card-text small" style={{ color: '#444444' }}>
                            {item.description.split('\n').map((line, index) => (
                              <div key={index}>{line}</div>
                            ))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleSlidesOnly"
            role="button"
            data-slide="prev"
            onClick={handleNextClick}
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleSlidesOnly"
            role="button"
            data-slide="next"
            onClick={handleNextClick}
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </>
  )
}

MadadghaarAgent.propTypes = {
  title: PropTypes.arrayOf(PropTypes.node).isRequired,
}
export default MadadghaarAgent
