import React from 'react'

const PropertiesServicesCard = ({ title, description, image, buttonTitle, onClick }) => {
  return (
    <div
      className="text-center container d-flex flex-column align-items-center bg-white py-5 rounded-lg w-sm-50 w-md-50"
      style={{ width: '20%' }}
    >
      <div style={{ width: '40%', height: '100px' }} className="text-center my-2 w-md-75">
        <img className="w-100" src={image} />
      </div>
      <h5>{title}</h5>
      <p className="w-50">{description}</p>
      <button type="button" className="btn btn-outline-dark my-2">
        {buttonTitle}
      </button>
    </div>
  )
}

export default PropertiesServicesCard
